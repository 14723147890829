$(function () {
    // Включаем отображение сообщений и ошибок Ajax-запросов в виде флеш-уведомлений
    $(document).on('ajaxSetup', function (event, context) {
        context.options.flash = true;
        context.options.loading = $.oc.stripeLoadIndicator;

        context.options.handleErrorMessage = function (message) {
            $.oc.flashMsg({text: message, class: 'error'})
        };

        context.options.handleFlashMessage = function (message, type) {
            $.oc.flashMsg({text: message, class: type})
        };
    });

    // Включаем всплывающие подсказки Bootstrap 4
    $("[data-toggle='tooltip']").tooltip();

    // Настройка datetimepicker'а для работы с FontAwesome 5
    $.extend(true, $.fn.datetimepicker.defaults, {
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-calendar-check',
            clear: 'far fa-trash-alt',
            close: 'far fa-times-circle'
        }
    });

    // Заполняем справочник брендов и марок машин
    let carData = {
        "Acura (Акура)": ["ACURA MDX I", "ACURA MDX II", "ACURA RDX I"],
        "Audi (Ауди)": ["AUDI A1", "AUDI A1 Sportback", "AUDI A2", "AUDI A3 I", "AUDI A3 II", "AUDI A3 III", "AUDI A3 Sportback II", "AUDI A3 Sportback III", "AUDI A3 седан", "AUDI A4 Allroad", "AUDI A4 Avant II", "AUDI A4 Avant III", "AUDI A4 Avant IV", "AUDI A4 II", "AUDI A4 III", "AUDI A4 IV", "AUDI A5/S5", "AUDI A6 Allroad I", "AUDI A6 Allroad II", "AUDI A6 Avant II", "AUDI A6 Avant III", "AUDI A6 Avant IV", "AUDI A6 II", "AUDI A6/S6 I", "AUDI A6/S6 II", "AUDI A7 Sportback", "AUDI A8 II", "AUDI A8 III", "AUDI Allroad", "AUDI Q3", "AUDI Q5", "AUDI Q7", "AUDI TT I", "AUDI TT II"],
        "BMW (БМВ)": ["BMW 1 кабрио", "BMW 1 купе", "BMW 1 хэтчбек 3дв. I", "BMW 1 хэтчбек 5дв. II", "BMW 3 купе IV", "BMW 3 купе V", "BMW 3 седан IV", "BMW 3 седан V", "BMW 3 седан VI", "BMW 3 универсал IV", "BMW 3 универсал V", "BMW 5 Gran Turismo", "BMW 5 седан IV", "BMW 5 седан V", "BMW 5 седан VI", "BMW 5 универсал IV", "BMW 5 универсал V", "BMW 5 универсал VI", "BMW 6 кабрио II", "BMW 6 купе II", "BMW 6 купе III", "BMW 7 III", "BMW 7 IV", "BMW 7 V", "BMW X1", "BMW X3 I", "BMW X3 II", "BMW X5 I", "BMW X5 II", "BMW X6", "BMW Z4 кабрио II"],
        "Chevrolet (Шевроле)": ["CHEVROLET Aveo седан II", "CHEVROLET Aveo седан III", "CHEVROLET Aveo хэтчбек II", "CHEVROLET Aveo хэтчбек III", "CHEVROLET Captiva", "CHEVROLET Cobalt седан II", "CHEVROLET Cruze седан", "CHEVROLET Cruze универсал", "CHEVROLET Cruze хэтчбек", "CHEVROLET Epica", "CHEVROLET Evanda", "CHEVROLET Lacetti седан", "CHEVROLET Lacetti универсал", "CHEVROLET Lacetti хэтчбек", "CHEVROLET Lanos седан", "CHEVROLET Orlando", "CHEVROLET Rezzo", "CHEVROLET Spark III"],
        "Citroen (Ситроен)": ["CITROEN Berlingo I", "CITROEN C-Crosser", "CITROEN C-Elysee", "CITROEN C1", "CITROEN C2", "CITROEN C3 I", "CITROEN C3 II", "CITROEN C3 Picasso", "CITROEN C3 Pluriel", "CITROEN C4 Aircross", "CITROEN C4 Grand Picasso", "CITROEN C4 Picasso", "CITROEN C4 купе", "CITROEN C4 хетчбек I", "CITROEN C4 хетчбек II", "CITROEN C5 Break универсал I", "CITROEN C5 Break универсал II", "CITROEN C5 седан", "CITROEN C5 хетчбек I", "CITROEN C5 хетчбек II", "CITROEN C6", "CITROEN DS3", "CITROEN DS4", "CITROEN DS5", "CITROEN Xsara Picasso", "CITROEN Xsara хетчбек 5 дв"],
        "Daewoo (Дэу)": ["DAEWOO Lanos седан II", "DAEWOO Leganza", "DAEWOO Matiz", "DAEWOO Nexia седан I", "DAEWOO Nexia седан II"],
        "Ford (Форд)": ["FORD C-Max I", "FORD Escape", "FORD Fiesta хэтчбек V", "FORD Fiesta хэтчбек VI", "FORD Focus C-Max", "FORD Focus кабрио", "FORD Focus седан I", "FORD Focus седан II", "FORD Focus седан II", "FORD Focus седан III", "FORD Focus универсал I", "FORD Focus универсал II", "FORD Focus универсал II", "FORD Focus универсал III", "FORD Focus хэтчбек I", "FORD Focus хэтчбек II", "FORD Focus хэтчбек II", "FORD Focus хэтчбек III", "FORD Fusion", "FORD Galaxy I", "FORD Galaxy II", "FORD Grand C-Max", "FORD KA III", "FORD Kuga I", "FORD Kuga II", "FORD Maverick II", "FORD Mondeo седан III", "FORD Mondeo седан IV", "FORD Mondeo универсал III", "FORD Mondeo универсал IV", "FORD Mondeo хэтчбек III", "FORD Mondeo хэтчбек IV", "FORD Ranger VI", "FORD S-Max", "FORD Tourneo Connect"],
        "Honda (Хонда)": ["HONDA Accord седан IX", "HONDA Accord седан VII", "HONDA Accord седан VIII", "HONDA Accord универсал IV", "HONDA Accord универсал V", "HONDA Civic купе VII", "HONDA Civic седан IX", "HONDA Civic седан VII", "HONDA Civic седан VIII", "HONDA Civic хэтчбек IX", "HONDA Civic хэтчбек VII", "HONDA Civic хэтчбек VIII", "HONDA CR-V I", "HONDA CR-V II", "HONDA CR-V III", "HONDA CR-V IV", "HONDA Crosstour", "HONDA Element", "HONDA HR-V", "HONDA Jazz I", "HONDA Jazz II", "HONDA Legend седан IV", "HONDA Pilot I", "HONDA Pilot II"],
        "Hyundai (Хендай)": ["HYUNDAI Accent седан I", "HYUNDAI Accent седан II", "HYUNDAI Accent седан III", "HYUNDAI Accent хэтчбек I", "HYUNDAI Accent/Verna хэтчбек", "HYUNDAI Coupe VII", "HYUNDAI Elantra седан I", "HYUNDAI Elantra седан II", "HYUNDAI Elantra седан III", "HYUNDAI Elantra хэтчбек I", "HYUNDAI Equus", "HYUNDAI Genesis купе", "HYUNDAI Genesis седан", "HYUNDAI Getz", "HYUNDAI Grandeur IV", "HYUNDAI Grandeur V", "HYUNDAI i10", "HYUNDAI i20", "HYUNDAI i30 CW универсал I", "HYUNDAI i30 I", "HYUNDAI i30 II", "HYUNDAI i40 CW", "HYUNDAI i40 седан", "HYUNDAI ix35", "HYUNDAI ix55", "HYUNDAI Matrix I", "HYUNDAI Matrix II", "HYUNDAI Santa Fe Classic Тагаз", "HYUNDAI Santa Fe I", "HYUNDAI Santa Fe II", "HYUNDAI Santa Fe III", "HYUNDAI Solaris хэтчбек", "HYUNDAI Solaris/Accent седан IV", "HYUNDAI Sonata III", "HYUNDAI Sonata V", "HYUNDAI Sonata VI", "HYUNDAI Sonata Тагаз", "HYUNDAI Terracan", "HYUNDAI Trajet", "HYUNDAI Tucson", "HYUNDAI Veloster"],
        "Infiniti (Инфинити)": ["INFINITI EX", "INFINITI FX I", "INFINITI FX II", "INFINITI G седан II", "INFINITI JX", "INFINITI M седан", "INFINITI QX56 I"],
        "Kia (Киа)": ["KIA Carens I", "KIA Carens II", "KIA Carens III", "KIA Carnival II", "KIA Carnival III", "KIA Cerato KOUP", "KIA Cerato седан I", "KIA Cerato седан II", "KIA Cerato седан III", "KIA Cerato хэтчбек I", "KIA Clarus седан", "KIA Joice", "KIA Magentis I", "KIA Magentis II", "KIA Mohave", "KIA Opirus", "KIA Optima II", "KIA Picanto I", "KIA Picanto II", "KIA Quoris", "KIA Rio седан I", "KIA Rio седан II", "KIA Rio седан III", "KIA Rio универсал I", "KIA Rio хэтчбек II", "KIA Rio хэтчбек III", "KIA Sephia седан II", "KIA Shuma седан II", "KIA Shuma хэтчбек II", "KIA Sorento I", "KIA Sorento II", "KIA Sorento III", "KIA Soul", "KIA Spectra", "KIA Sportage I", "KIA Sportage II", "KIA Sportage III", "KIA Venga"],
        "Lexus (Лексус)": ["LEXUS CT", "LEXUS ES IV", "LEXUS GS I", "LEXUS GS II", "LEXUS GS III", "LEXUS GS IV", "LEXUS GX I", "LEXUS GX II", "LEXUS IS 200/300 седан I", "LEXUS IS 250-220D седан II", "LEXUS IS 250C Кабриолет", "LEXUS IS SportCross", "LEXUS IS седан III", "LEXUS LS 400 II", "LEXUS LS 430 III", "LEXUS LS 460/460L/600H IV", "LEXUS LX I", "LEXUS LX II", "LEXUS RX I", "LEXUS RX II", "LEXUS RX III", "LEXUS SC 430"],
        "Mazda (Мазда)": ["MAZDA 323 седан VI", "MAZDA 323 хэтчбек VI", "MAZDA 626 седан V", "MAZDA 626 универсал V", "MAZDA 626 хэтчбек V", "MAZDA BT-50", "MAZDA CX-5", "MAZDA CX-7", "MAZDA CX-9", "MAZDA Mazda 2 хэтчбек I", "MAZDA Mazda 2 хэтчбек II", "MAZDA Mazda 3 седан I", "MAZDA Mazda 3 седан II", "MAZDA Mazda 3 хэтчбек I", "MAZDA Mazda 3 хэтчбек II", "MAZDA Mazda 5 I", "MAZDA Mazda 5 II", "MAZDA Mazda 6 седан I", "MAZDA Mazda 6 седан II", "MAZDA Mazda 6 седан III", "MAZDA Mazda 6 универсал I", "MAZDA Mazda 6 универсал II", "MAZDA Mazda 6 хэтчбек I", "MAZDA Mazda 6 хэтчбек II", "MAZDA MX-5 III", "MAZDA RX-8 I", "MAZDA RX-8 II", "MAZDA Tribute"],
        "Mercedes-Benz (Мерседес)": ["MERCEDES A-Class I", "MERCEDES A-Class II", "MERCEDES B-Class I", "MERCEDES B-Class II", "MERCEDES C-Class купе II", "MERCEDES C-Class седан III", "MERCEDES C-Class универсал III", "MERCEDES CLS I", "MERCEDES CLS II", "MERCEDES E-Class кабрио II", "MERCEDES E-Class купе II", "MERCEDES E-Class седан V", "MERCEDES E-Class универсал III", "MERCEDES E-Class универсал IV", "MERCEDES GL-Class I", "MERCEDES GL-Class II", "MERCEDES GLK-Class", "MERCEDES ML-Class I", "MERCEDES ML-Class II", "MERCEDES ML-Class III", "MERCEDES R-Class", "MERCEDES S-Class VI", "MERCEDES SL VI", "MERCEDES SLK II", "MERCEDES SLK III", "MERCEDES SLS AMG"],
        "Mitsubishi (Митсубиcи)": ["MITSUBISHI ASX", "MITSUBISHI Carisma седан", "MITSUBISHI Carisma хэтчбек", "MITSUBISHI Colt VI", "MITSUBISHI Galant седан IX", "MITSUBISHI Galant седан VIII", "MITSUBISHI Galant универсал VIII", "MITSUBISHI Grandis II", "MITSUBISHI L 200 III", "MITSUBISHI L 200 IV", "MITSUBISHI Lancer Evolution IX", "MITSUBISHI Lancer Evolution X", "MITSUBISHI Lancer Sportback X", "MITSUBISHI Lancer Station Wagon IX", "MITSUBISHI Lancer Station Wagon VI", "MITSUBISHI Lancer седан IX", "MITSUBISHI Lancer седан VII", "MITSUBISHI Lancer седан X", "MITSUBISHI Outlander I", "MITSUBISHI Outlander III", "MITSUBISHI Outlander XL II", "MITSUBISHI Pajero II", "MITSUBISHI Pajero III", "MITSUBISHI Pajero IV", "MITSUBISHI Pajero Pinin", "MITSUBISHI Pajero Sport I", "MITSUBISHI Pajero Sport II", "MITSUBISHI Space Star"],
        "Nissan (Ниссан)": ["NISSAN 350 Z", "NISSAN Almera Classic", "NISSAN Almera Tino", "NISSAN Almera седан I", "NISSAN Almera седан II", "NISSAN Almera седан IV", "NISSAN Almera хэтчбек I", "NISSAN Almera хэтчбек II", "NISSAN Juke", "NISSAN Maxima III", "NISSAN Maxima IV", "NISSAN Micra C+C", "NISSAN Micra III", "NISSAN Murano I", "NISSAN Murano II", "NISSAN Navara III", "NISSAN Note I", "NISSAN NP300", "NISSAN Pathfinder III", "NISSAN Patrol III", "NISSAN Patrol IV", "NISSAN Primera седан III", "NISSAN Primera универсал III", "NISSAN Primera хэтчбек III", "NISSAN Qashqai", "NISSAN Qashqai+2", "NISSAN Teana I", "NISSAN Teana II", "NISSAN Tiida седан", "NISSAN Tiida хэтчбек II", "NISSAN X-Trail I", "NISSAN X-Trail II"],
        "Opel (Опель)": ["OPEL Agila A", "OPEL Agila B", "OPEL Antara", "OPEL Astra G купе", "OPEL Astra G седан", "OPEL Astra G универсал", "OPEL Astra G хэтчбек", "OPEL Astra H GTC", "OPEL Astra H TwinTop", "OPEL Astra H седан", "OPEL Astra H универсал", "OPEL Astra H хэтчбек", "OPEL Astra J GTC", "OPEL Astra J Sports Tourer", "OPEL Astra J хэтчбек", "OPEL Corsa C", "OPEL Corsa D", "OPEL Frontera B", "OPEL Insignia седан", "OPEL Insignia универсал", "OPEL Insignia хэтчбек", "OPEL Meriva I", "OPEL Meriva II", "OPEL Mokka", "OPEL Monterey A", "OPEL Monterey B", "OPEL Omega B седан", "OPEL Omega B универсал", "OPEL Speedster", "OPEL Tigra B", "OPEL Vectra B седан", "OPEL Vectra B универсал", "OPEL Vectra B хэтчбек", "OPEL Vectra C GTS", "OPEL Vectra C седан", "OPEL Vectra C универсал", "OPEL Zafira A", "OPEL Zafira B", "OPEL Zafira C"],
        "Peugeot (Пежо)": ["PEUGEOT 1007", "PEUGEOT 107", "PEUGEOT 206 седан", "PEUGEOT 206 хэтчбек", "PEUGEOT 207 CC", "PEUGEOT 207 SW", "PEUGEOT 207 хэтчбек", "PEUGEOT 208", "PEUGEOT 3008", "PEUGEOT 301", "PEUGEOT 307 хэтчбек", "PEUGEOT 308 CC", "PEUGEOT 308 SW", "PEUGEOT 308 хэтчбек I", "PEUGEOT 4007", "PEUGEOT 407 SW", "PEUGEOT 407 седан", "PEUGEOT 408", "PEUGEOT 5008", "PEUGEOT 508 SW", "PEUGEOT 508 седан", "PEUGEOT 607", "PEUGEOT 807", "PEUGEOT Partner Tepee", "PEUGEOT RCZ"],
        "Pontiac (Понтиак)": ["PONTIAC Vibe универсал I"],
        "Renault (Рено)": ["RENAULT Clio Grandtour III", "RENAULT Clio II", "RENAULT Clio III", "RENAULT Clio фургон II", "RENAULT Duster", "RENAULT Fluence", "RENAULT Grand Scenic II", "RENAULT Grand Scenic III", "RENAULT Kangoo Express I", "RENAULT Kangoo Express II", "RENAULT Kangoo I", "RENAULT Koleos", "RENAULT Laguna Grandtour II", "RENAULT Laguna Grandtour III", "RENAULT Laguna купе III", "RENAULT Laguna хэтчбек I", "RENAULT Laguna хэтчбек II", "RENAULT Laguna хэтчбек III", "RENAULT Latitude", "RENAULT Logan седан I", "RENAULT Megane CC III", "RENAULT Megane Coupe-Cabriolet II", "RENAULT Megane Grandtour I", "RENAULT Megane Grandtour III", "RENAULT Megane купе III", "RENAULT Megane седан II", "RENAULT Megane универсал II", "RENAULT Megane хэтчбек II", "RENAULT Megane хэтчбек III", "RENAULT Sandero", "RENAULT Sandero Stepway", "RENAULT Scenic I", "RENAULT Scenic II", "RENAULT Scenic III", "RENAULT Symbol/Thalia I", "RENAULT Symbol/Thalia II", "RENAULT Twingo I", "RENAULT Twingo II", "RENAULT Wind"],
        "Rover (Ровер)": ["ROVER 75"],
        "Seat (Сеат)": ["SEAT Alhambra I", "SEAT Alhambra II", "SEAT Altea", "SEAT Altea XL", "SEAT Arosa", "SEAT Cordoba седан III", "SEAT Exeo ST", "SEAT Exeo седан", "SEAT Ibiza IV", "SEAT Ibiza V", "SEAT Inca", "SEAT Leon I", "SEAT Leon II", "SEAT Leon III", "SEAT Leon SC III", "SEAT Toledo II", "SEAT Toledo III"],
        "Skoda (Шкода)": ["SKODA Fabia седан", "SKODA Fabia универсал I", "SKODA Fabia универсал II", "SKODA Fabia хэтчбек I", "SKODA Fabia хэтчбек II", "SKODA Octavia I", "SKODA Octavia II", "SKODA Octavia III", "SKODA Octavia универсал I", "SKODA Octavia универсал II", "SKODA Octavia универсал III", "SKODA Roomster", "SKODA Superb I", "SKODA Superb II", "SKODA Superb универсал II", "SKODA Yeti"],
        "SsangYong (Санг Енг)": ["SSANGYONG Actyon", "SSANGYONG Actyon Sports II", "SSANGYONG Actyon sports пикап I", "SSANGYONG Chairman", "SSANGYONG Korando II", "SSANGYONG Kyron", "SSANGYONG Musso", "SSANGYONG Musso Sports", "SSANGYONG New Actyon/Korando", "SSANGYONG Rexton", "SSANGYONG Rodius I"],
        "Subaru (Субару)": ["SUBARU BRZ", "SUBARU Forester II", "SUBARU Forester III", "SUBARU Forester IV", "SUBARU Impreza седан II", "SUBARU Impreza седан III", "SUBARU Impreza универсал II", "SUBARU Impreza хетчбек III", "SUBARU Legacy седан III", "SUBARU Legacy седан IV", "SUBARU Legacy седан V", "SUBARU Legacy универсал III", "SUBARU Legacy универсал IV", "SUBARU Legacy универсал V", "SUBARU Outback II", "SUBARU Outback III", "SUBARU Tribeca", "SUBARU XV"],
        "Suzuki (Сузуки)": ["SUZUKI Grand Vitara I", "SUZUKI Grand Vitara II", "SUZUKI Ignis II", "SUZUKI Kizashi", "SUZUKI Liana седан", "SUZUKI Liana универсал", "SUZUKI Splash", "SUZUKI Swift III", "SUZUKI SX4 седан", "SUZUKI SX4 хэтчбек"],
        "Toyota (Тойота)": ["TOYOTA 4Runner III", "TOYOTA Auris хэтчбек I", "TOYOTA Avensis седан I", "TOYOTA Avensis седан II", "TOYOTA Avensis седан III", "TOYOTA Avensis универсал I", "TOYOTA Avensis универсал II", "TOYOTA Avensis универсал III", "TOYOTA Avensis хэтчбек I", "TOYOTA Avensis хэтчбек II", "TOYOTA Camry седан IV", "TOYOTA Camry седан V", "TOYOTA Camry седан VI", "TOYOTA Camry седан VII", "TOYOTA Corolla Verso I", "TOYOTA Corolla Verso II", "TOYOTA Corolla седан IX", "TOYOTA Corolla седан VIII", "TOYOTA Corolla седан X", "TOYOTA Corolla универсал IX", "TOYOTA Corolla универсал VIII", "TOYOTA Corolla хэтчбек IX", "TOYOTA Corolla хэтчбек VIII", "TOYOTA GT 86 купе", "TOYOTA Highlander II", "TOYOTA Hilux пикап III", "TOYOTA IQ", "TOYOTA Land Cruiser 100", "TOYOTA Land Cruiser IV", "TOYOTA Land Cruiser Prado 90", "TOYOTA Land Cruiser Prado I", "TOYOTA Land Cruiser Prado II", "TOYOTA Previa II", "TOYOTA Prius II", "TOYOTA Prius III", "TOYOTA Rav 4 I", "TOYOTA Rav 4 II", "TOYOTA Rav 4 III", "TOYOTA Rav 4 IV", "TOYOTA Urban Cruiser", "TOYOTA Venza", "TOYOTA Verso", "TOYOTA Yaris седан", "TOYOTA Yaris хэтчбек I", "TOYOTA Yaris хэтчбек II"],
        "Volkswagen (Фольксваген)": ["VOLKSWAGEN Polo sedan", "VOLKSWAGEN Bora седан", "VOLKSWAGEN Bora универсал", "VOLKSWAGEN Caddy универсал III", "VOLKSWAGEN Caddy фургон III", "VOLKSWAGEN Golf IV", "VOLKSWAGEN Golf V", "VOLKSWAGEN Golf Variant IV", "VOLKSWAGEN Golf Variant V", "VOLKSWAGEN Golf Variant VI", "VOLKSWAGEN Golf VI", "VOLKSWAGEN Jetta V", "VOLKSWAGEN Jetta VI", "VOLKSWAGEN Passat Alltrack", "VOLKSWAGEN Passat CC I", "VOLKSWAGEN Passat CC II", "VOLKSWAGEN Passat Variant V", "VOLKSWAGEN Passat Variant VI", "VOLKSWAGEN Passat Variant VII", "VOLKSWAGEN Passat седан V", "VOLKSWAGEN Passat седан VI", "VOLKSWAGEN Passat седан VII", "VOLKSWAGEN Phaeton", "VOLKSWAGEN Polo седан V", "VOLKSWAGEN Polo хэтчбек IV", "VOLKSWAGEN Polo хэтчбек V", "VOLKSWAGEN Scirocco III", "VOLKSWAGEN Sharan I", "VOLKSWAGEN Tiguan", "VOLKSWAGEN Touareg I", "VOLKSWAGEN Touareg II", "VOLKSWAGEN Touran I", "VOLKSWAGEN Touran II", "VOLKSWAGEN Up"],
        "Volvo (Вольво)": ["VOLVO C30", "VOLVO C70 кабрио II", "VOLVO S40 I", "VOLVO S40 II", "VOLVO S60 I", "VOLVO S60 II", "VOLVO S70", "VOLVO S80 I", "VOLVO S80 II", "VOLVO V40 Cross Country", "VOLVO V40 универсал", "VOLVO V50", "VOLVO V60", "VOLVO V70 универсал I", "VOLVO V70 универсал II", "VOLVO V70 универсал III", "VOLVO XC60", "VOLVO XC70 Cross Country/ V70XC I", "VOLVO XC70 II", "VOLVO XC90"]
    };

    fillSelects(carData);

    // Навешиваем datetimepicker
    $('[data-datetimepicker]').datetimepicker({
        locale: 'ru',
        defaultDate: moment().add(1, 'days').hour(12).minute(0).second(0),
        minDate: moment()
    });

    // Следим за табами
    $('a[data-next]').click(function () {
        let nextTab = $(this).attr("data-next");
        let parentTabId = $(this).closest('.tab-pane').attr('id');

        $(`#${parentTabId}-tab`).addClass('success');
        $(`#${nextTab}-tab`).removeClass('disabled').click();
    });

    // Обрабатываем закрытие модального окна
    $('#priceCheckModal, #serviceSignUpModal').on('hidden.bs.modal', function () {
        if ($(this).find('#finish-tab').hasClass('active')) {
            $(this).find('nav').removeClass('d-none');

            $(this).find('.nav-item').each(function (index) {
                $(this).removeClass('success');
                $(this).removeClass('active');

                if (index) {
                    $(this).addClass('disabled');
                } else {
                    $(this).click();
                }
            });
        }
    });
});

/**
 * Скрывает активное в данный момент модальное окно.
 *
 * @param timeout Задержка закрытия окна в миллисекундах
 */
function hideActiveModal(timeout = 1000) {
    let $modal = $('.modal.show');

    if ($modal.length) {
        setTimeout(function () {
            $modal.modal('hide');
        }, timeout);
    }
}

function fillSelects(carData) {
    let Select2Cascade = (function (window, $) {
        function Select2Cascade(parent, child) {
            parent.on("change", function () {
                child.prop("disabled", true);

                let newOptions = '<option>Выберите модель</option>';
                for (let brand of carData[$(this).val()]) {
                    newOptions += `<option value="${brand}">${brand}</option>`;
                }

                child.html(newOptions).prop("disabled", false);
            });
        }

        return Select2Cascade;
    })(window, $);

    // Заполняем первый селект элементами
    let newOptions = '<option>Выберите марку</option>';
    for (let model in carData) {
        newOptions += `<option value="${model}">${model}</option>`;
    }

    let $brandSelect = $('#marka');
    $brandSelect.html(newOptions).prop("disabled", false);

    new Select2Cascade($brandSelect, $('#model'));
}
